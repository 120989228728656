import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {
    user!: User;
    currentUserValue: any;
    private API_URL = environment.apiURL;
    private refreshTokenInProgress = false;
    private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    
    constructor(
      private httpClient: HttpClient,
      private router: Router,
      @Inject(DOCUMENT) public document: Document
    ) {
        console.log(environment.apiURL);
    }

    // Interceptor implementation
    login(creds: any): Observable<any> {
        return this.httpClient.post<any>(`${this.API_URL}/v1/auth/jwt/create/`, creds).pipe(
            tap(tokens => this.storeTokens(tokens))
        );
    }

    private storeTokens(tokens: any) {
        this.setCookie('Token', tokens.access); // Session cookie
        this.setCookie('refresh_token', tokens.refresh); // Session cookie
    }

    setCookie(name: string, value: string) {
        const domain = window.location.hostname === 'localhost' ? '' : `domain=`;
        const secureFlag = window.location.protocol === 'https:' ? 'Secure' : '';
        this.document.cookie = `${name}=${value}; SameSite=Lax; ${secureFlag}; ${domain};path=/`;
    }

    private getCookie(name: string): string | null {
        const nameEQ = `${name}=`;
        const ca = this.document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    getAccessToken(): string | null {
        return this.getCookie('Token');
    }

    private getRefreshToken(): string | null {
        return this.getCookie('refresh_token');
    }

    private refreshToken(): Observable<any> {
        if (this.refreshTokenInProgress) {
            return this.accessTokenSubject.asObservable().pipe(
                switchMap(token => {
                    return token ? of(token) : throwError('No token available');
                })
            );
        } else {
            this.refreshTokenInProgress = true;
            this.accessTokenSubject.next(null);

            return this.httpClient.post<any>(`${this.API_URL}/v1/auth/jwt/refresh`, {
                refresh: this.getRefreshToken()
            }).pipe(
                tap(tokens => this.storeTokens(tokens)),
                catchError(error => {
                    this.logout();
                    return throwError(error);
                }),
                tap((tokens: any) => {
                    this.accessTokenSubject.next(tokens.access);
                    this.refreshTokenInProgress = false;
                })
            );
        }
    }

    getNewAccessToken(): Observable<any> {
        return this.refreshToken().pipe(
            switchMap(() => {
                return of(this.getAccessToken());
            })
        );
    }

    logout() {
        this.deleteCookie('Token');
        this.deleteCookie('refresh_token');
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/account/login']);
    }

    private deleteCookie(name: string) {
        const domain = window.location.hostname === 'localhost' ? '' : `domain=`;
        const secureFlag = window.location.protocol === 'https:' ? 'Secure' : '';
        this.document.cookie = `${name}=; SameSite=Lax; ${secureFlag}; ${domain};path=/`;
    }

    isLoggedIn(): boolean {
        const token = this.getAccessToken();
        if (!token) return false;
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiry = payload.exp;
        return (Math.floor((new Date).getTime() / 1000)) < expiry;
    }
}
