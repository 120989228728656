import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getAccessToken();
        if (this.authService.isLoggedIn() && token) {
            request = this.addToken(request, token);
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    const originalUrl = this.router.url;
                    if (!this.refreshTokenInProgress) {
                        this.refreshTokenInProgress = true;
                        this.accessTokenSubject.next(null);

                        return this.authService.getNewAccessToken().pipe(
                            switchMap(newToken => {
                                if (newToken) {
                                    this.accessTokenSubject.next(newToken);
                                    this.refreshTokenInProgress = false;
                                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                        this.router.navigate([originalUrl], { relativeTo: this.route });
                                    });
                                    request = this.addToken(request, newToken);
                                    return next.handle(request);
                                }
                                this.authService.logout();
                                return throwError('Unable to refresh token');
                            }),
                            catchError(err => {
                                this.refreshTokenInProgress = false;
                                this.authService.logout();
                                return throwError(err);
                            })
                        );
                    } else {
                        return this.accessTokenSubject.pipe(
                            switchMap(token => {
                                if (token) {
                                    request = this.addToken(request, token);
                                    return next.handle(request);
                                }
                                this.authService.logout();
                                return throwError('No token available');
                            }),
                            catchError(err => {
                                this.authService.logout();
                                return throwError(err);
                            })
                        );
                    }
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `JWT ${token}`
            }
        });
    }
}